/** ------------------------------
    Mailchimp Forms
    ------------------------------ */
#mc_embed_signup {
    margin: 30px auto;
    max-width: 600px;

    & .indicates-required {
        margin: 10px 0;
        text-align: right;
        }

    & p:empty {
        display: none;
        }

    & label {
        display: block;
        width: 100%;
        }

    & input {
        &:matches([type="text"], [type="email"]) {
            @mixin form-control;
            }

        &:matches([type="checkbox"], [type="radio"]) {
            margin-right: 5px;
            }

        &:matches([type="submit"]) {
            @mixin button;
            min-width: 50%;
            }
        }

    & .mc-field-group {
        margin-bottom: 20px;
        text-align: left;

        & br {
            display: none;
            }
        }

    & .input-group {
        & ul {
            @mixin list-reset;
            margin-top: 10px;
            }

        & label {
            display: inline;
            }
        }
    }

.mc4wp-form-fields {
    > p {
        > label {
            display: block;
            margin-top: 8px;
        }
    }
    > p.inline {
        > label {
            display: inline;
            margin-top: 8px;
        }
    }
    input[type='text'], input[type='email'], input[type='password'] {
        display: block;
        width: 100%;
        margin: .5rem 0 0;
        padding: 0 .75rem;
        height: 2.57rem;
        line-height: 2.57rem;
        border: 1px solid #bcc6d6;
        border-radius: 3px;
        background-color: #fff;
        font-family: inherit;
        font-weight: 300;
        color: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    input[type='checkbox'] {
        display: inline;
    }
    input[type='submit'], button.primary {
        display: inline-block;
        padding: 0 .75rem;
        min-height: 2.5rem;
        border: 0;
        border-radius: .2em;
        background-color: #ff006d;
        font-family: inherit;
        font-weight: 400;
        color: #fff;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        min-width: 150px;
        margin: 5px 0;
        width: 100%;
        height: 3.125rem;
        line-height: 3.125rem;
        cursor: pointer;
        &:hover {
            -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
            box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
        }
    }
}